@charset "UTF-8";
/*
 * frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
 */
/* ---- Global Variables ---- */
/* ---- Fonts ---- */
/*

Montserrat
Regular 400
Bold 400
font-family: 'Montserrat', Helvetica, Arial, sans-serif;

Lato
Regular 400
Bold 700
font-family: "lato", Helvetica, Arial, sans-serif;

Mr Eaves Modern
Book 300
Regular 400
font-family: "mr-eaves-modern", Helvetica, Arial, sans-serif;

*/
/* Iconfont */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/fonts/icomoon.eot?crikey");
  src: url("../fonts/icomoon/fonts/icomoon.eot?crikey#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf?crikey") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff?crikey") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg?crikey#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (min-width: 25em) {
  /* 400px */
}
@media screen and (min-width: 30em) {
  /* 480px */
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
}
/* BP 1 */
@media screen and (min-width: 37.5em) {
  /* 600px */
}
/* BP 2 */
@media screen and (min-width: 48.0625em) {
  /* 769px */
}
/* BP 3 */
@media screen and (min-width: 58.75em) {
  /* 940px */
}
/* BP 4 */
@media screen and (min-width: 76.875em) {
  /* 1230px */
}
/* BP 5 */
@media screen and (min-width: 86.25em) {
  /* 1380px */
}
/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

#frankenmenu-list a {
  text-decoration: none;
  display: block;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 1.6rem;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 940px) {
  #frankenmenu {
    z-index: 1000;
    max-width: 90em;
    /* 1800px */
    margin: auto;
  }

  #frankenmenu-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }

  #mobile-header {
    display: none;
  }

  /* ---- All level menu items ---- */
  #frankenmenu-list .menu-item {
    position: relative;
  }

  #frankenmenu-list .sub-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    -webkit-box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    min-width: 250px;
    max-width: 350px;
    text-align: left;
    top: 0;
    left: 100%;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 {
    text-align: center;
    flex: 0 1 auto;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    color: #232C35;
    display: block;
    font-size: 2rem;
    padding: 41px 0 17px;
    font-family: "mr-eaves-modern", Helvetica, Arial, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    border-bottom: 4px solid transparent;
    white-space: nowrap;
  }

  .sticky-nav #frankenmenu-list .menu-item-depth1 > a {
    padding-top: 22px;
    padding-bottom: 8px;
  }

  #frankenmenu-list .menu-item-depth1 > a:hover,
#frankenmenu-list .menu-item-depth1 > a:focus,
#frankenmenu-list .menu-item-depth1.frankenhover > a,
#frankenmenu-list .menu-item-depth1.frankenhold > a {
    border-bottom-color: #232C35;
  }

  #frankenmenu-list .menu-item-depth1.menu-current-item > a {
    border-bottom-color: #F70000;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu a {
    border-top: 1px solid #a21a2e;
    padding: 11px 20px 13px;
    color: #FFFFFF;
    font-size: 1.6rem;
    line-height: 1.8rem;
    background-color: #c5253d;
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
    text-transform: lowercase;
  }

  #frankenmenu-list .sub-menu .menu-item:first-child a {
    border-top: none;
  }

  #frankenmenu-list .sub-menu .menu-item > a:hover,
#frankenmenu-list .sub-menu .menu-item > a:focus,
#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
    background: #a21a2e;
    color: #FFF;
  }

  #frankenmenu-list .frankenhold > .sub-menu {
    display: block !important;
    opacity: 1 !important;
  }

  /* ---- First level submenus ---- */
  #frankenmenu-list .sub-menu-depth1 {
    top: 100%;
    left: auto;
  }

  /* ---- Right aligned submenus ---- */
  #frankenmenu-list .sub-menu.submenu-right-align,
#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
    left: auto;
    right: 100%;
  }

  #frankenmenu-list .sub-menu-depth1.submenu-right-align {
    left: auto;
    right: 0;
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-list .menu-item-subnav {
    display: none;
  }

  #frankenmenu-subnav-list,
#frankenmenu-subnav-list ul,
.footer-nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #frankenmenu-subnav-list {
    padding: 24px 0 10px 14px;
    margin-right: -16px;
  }

  .footer-nav {
    padding: 14px 0 10px 14px;
    margin-right: -16px;
  }

  #frankenmenu-subnav-list .menu-item-depth1,
.footer-nav__item {
    display: inline-block;
    vertical-align: top;
    border-left: 2px solid #565f67;
  }

  .footer-nav__item {
    border-left: 2px solid #FC8787;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a,
.footer-nav__item > a {
    text-decoration: none;
    padding: 0 16px 0;
    display: block;
    color: #565f67;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .footer-nav__item > a {
    color: #FC8787;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a {
    color: #788590;
  }

  #frankenmenu-subnav-list .menu-item-depth1.menu-current-item > a {
    color: #f1eeee;
  }

  .footer-nav__item > a {
    color: #FC8787;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
    color: #c2bdbd;
  }

  .footer-nav__item > a:hover {
    color: #ffffff;
  }

  /* Home */
  #frankenmenu-subnav-list .menu-item-depth1.menu-home-page,
.footer-nav__item--home {
    border: 0px;
  }

  #frankenmenu-subnav-list .menu-item-depth1.menu-home-page > a,
.footer-nav__item--home > a {
    position: relative;
    width: 33px;
    text-indent: -9999px;
    margin-right: 8px;
  }

  #frankenmenu-subnav-list .menu-item-depth1.menu-home-page > a:after,
.footer-nav__item--home > a:after {
    content: "";
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 2.1rem;
    top: 45%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    margin-top: 1px;
    text-indent: 0;
  }

  #frankenmenu-subnav-list .sub-menu {
    display: none;
  }

  .footer-nav {
    text-align: right;
  }

  /* ---- Moved elements ---- */
  .frankenmoved {
    display: none;
  }

  /* ---- Megamenu ---- */
  #frankenmenu-list .mega-menu {
    left: 0;
    right: 0;
    background-color: #FFF;
    text-align: left;
    top: 100%;
    position: absolute;
    border-bottom: 4px solid #f1eeee;
    z-index: 51;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }

  /* Remove relative positioning for mega menu */
  .frankenmenu-mega #frankenmenu-list .menu-item {
    position: static;
  }

  #frankenmenu-list .menu-item.frankenhover .mega-menu,
#frankenmenu-list .menu-item.frankenhold .mega-menu {
    padding: 34px 0;
    width: auto;
    height: auto;
    clip: auto;
    overflow: visible;
    margin: auto;
  }

  #frankenmenu-list .menu-item.frankenhold .mega-menu {
    display: block !important;
    height: auto !important;
  }

  /* Megamenu preview */
  #frankenmenu-list .mega-menu-preview-title {
    margin-top: 0;
  }

  #frankenmenu-list .mega-menu-preview-image {
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  #frankenmenu-list .mega-menu-preview-text {
    margin-bottom: 0;
    line-height: 1.2;
  }

  /* Megamenu columns */
  #frankenmenu-list .mega-menu .mega-menu-columns {
    padding-top: 41px;
  }

  /* Megamenu submenus */
  #frankenmenu-list .mega-menu-submenu .menu-item {
    border-top: 1px solid #f1eeee;
  }

  #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
    border-bottom: 1px solid #f1eeee;
  }

  #frankenmenu-list .mega-menu-submenu a {
    color: #232C35;
    padding: 5px 0;
  }

  #frankenmenu-list .mega-menu-submenu a:hover {
    color: #2f3a44;
  }
}
/** BP 4 - Desktop only
===================================*/
@media all and (max-width: 1229px) and (min-width: 940px) {
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.6rem;
    padding: 32px 3px 10px;
  }

  #frankenmenu-list .menu-item-depth1.menu-item-has-children > a:after {
    margin: 6px 0 0 2px;
    font-size: 11px;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a,
.footer-nav__item > a {
    font-size: 1.4rem;
  }

  #frankenmenu-list .sub-menu a {
    padding: 9px 15px 9px;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  #frankenmenu-list .mega-menu-preview-image {
    height: 106px;
  }

  #frankenmenu-list .menu-item.frankenhover .mega-menu,
#frankenmenu-list .menu-item.frankenhold .mega-menu {
    padding: 24px 0;
  }
}
@media screen and (min-width: 86.25em) {
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 102.5em) {
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 2.4rem;
  }
}
/** Mobile menu only
===================================*/
@media (max-width: 939px) {
  /* Force iOS click events trigger anywhere */
  html {
    cursor: pointer;
  }

  #wrap {
    margin-top: 40px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #FFF;
  }

  #frankenmenu {
    top: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #a21a2e;
    width: 70%;
    z-index: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding-top: 64px;
  }

  #frankenmenu .container {
    position: relative;
    padding: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    opacity: 0;
  }

  /* ---- Mobile header ---- */
  #mobile-header {
    background-color: #F2F2F2;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  #mobile-menu-button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    padding: 0;
    text-indent: -9999px;
    width: 40px;
    height: 36px;
    margin-top: 2px;
    position: relative;
    background: none;
  }

  .mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
    height: 3px;
    background-color: #000;
    left: 6px;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    position: absolute;
    display: block;
    right: 6px;
  }

  .mobile-menu-button-top {
    top: 8px;
  }

  .mobile-menu-button-middle {
    top: 17px;
  }

  .mobile-menu-button-bottom {
    top: 26px;
  }

  /* Animations active */
  .frankenmenu-mob-menu-animations .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopClose;
    animation-name: mobileMenuBtnTopClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleClose;
    animation-name: mobileMenuBtnMiddleClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomClose;
    animation-name: mobileMenuBtnBottomClose;
  }

  /* Close button */
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopOpen;
    animation-name: mobileMenuBtnTopOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleOpen;
    animation-name: mobileMenuBtnMiddleOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomOpen;
    animation-name: mobileMenuBtnBottomOpen;
  }

  @-webkit-keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  @keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list, #frankenmenu-list ul {
    width: 100%;
  }

  #frankenmenu-list .menu-item {
    position: relative;
    border-top: 1px solid #901124;
    float: none;
    width: 100%;
  }

  #frankenmenu-list a {
    min-height: 34px;
    width: 100%;
    padding: 7px 85px 8px 20px;
    color: #FFF;
  }

  #frankenmenu-list .menu-item.menu-current-item > a {
    color: #fff;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1:last-child {
    border-bottom: 1px solid #901124;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu {
    display: none;
  }

  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    display: block;
  }

  /* Depth differences */
  #frankenmenu-list .menu-item-depth1 > a {
    padding-left: 20px;
    background-color: #a21a2e;
    font-family: "mr-eaves-modern", Helvetica, Arial, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
  }

  #frankenmenu-list .menu-item-depth2 a {
    font-size: 1.3rem;
  }

  #frankenmenu-list .menu-item-depth2 > a {
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  #frankenmenu-list .menu-item-depth3 > a {
    padding-left: 35px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  #frankenmenu-list .menu-item-depth4 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  #frankenmenu-list .menu-item-depth5 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  #frankenmenu-list .menu-item-depth6 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  /* Expand buttons */
  .submenu-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    color: #FFF;
    font-size: 2.3rem;
    background: none;
    width: 55px;
    height: 33px;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 0;
  }

  .submenu-toggle:before {
    content: "";
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    background: none;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 -50% 0 0;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }

  .menu-item-submenu-open > .submenu-toggle:before {
    content: "";
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-subnav-list {
    display: none;
  }

  /* ---- Menu positions ---- */
  /* Left */
  .frankenmenu-mobile-pos-left #frankenmenu {
    left: 0;
  }

  .frankenmenu-mobile-pos-left #frankenmenu .container {
    -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
    transform: translate3d(-10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(70%, 0, 0);
    transform: translate3d(70%, 0, 0);
  }

  /* Right */
  .frankenmenu-mobile-pos-right #frankenmenu {
    right: 0;
  }

  .frankenmenu-mobile-pos-right #frankenmenu .container {
    -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
    transform: translate3d(10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }

  .frankenmenu-mobile-pos-right #mobile-menu-button {
    float: right;
  }

  /* ---- Transitions ---- */
  #frankenmenu,
#wrap,
#frankenmenu .container {
    -webkit-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
  }

  .js #frankenmenu {
    opacity: 0;
  }

  /* Menu open */
  .frankenmenu-mob-menu-visible #frankenmenu {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .frankenmenu-mob-menu-visible #frankenmenu .container {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }

  /* ---- Moved elements ---- */
  .frankenmove {
    display: none;
  }

  /* ---- Megamenu ---- */
  .mega-menu-preview {
    display: none;
  }

  .mega-menu-preview-image {
    background-image: none !important;
  }

  .mega-menu-column-title {
    color: #FFF;
    font-size: 1.6rem;
    padding: 3px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  #frankenmenu-list .mega-menu {
    display: none;
  }

  #frankenmenu-list .menu-current-item-ancestor .mega-menu {
    display: block;
  }

  #frankenmenu-list .mega-menu ul {
    display: block;
  }
}